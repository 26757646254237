<template>
  <div>
    <maca-titulo-pagina
      icono="icon-user"
      nombrePagina="Liquidaciones"
    ></maca-titulo-pagina>
    <el-card :body-style="{ padding: '1.5%' }" style="margin: 2%">
      <div slot="header">
        <div style="margin-left: 0px">
          <!-- <el-input
            class="buscador tamañoInputBucar"
            prefix-icon="el-icon-search"
            placeholder="Presione ENTER para buscar"
            v-model="filtroNombre"
            @change="buscar"
          >
          </el-input> -->
          <maca-select-box
            class="buscador tamañoInputBucar"
            placeholder="Ingresar nombre de transportista"
            :item-label="['apellido', 'nombre', 'razonSocial']"
            v-model="transportista"
            :url="urlTransportista"
            :permitirInput="true"
            :clearable="true"
            @change="seleccionSelect"
            :necesitaParams="true"
            :getParams="getParams"
          ></maca-select-box>
        </div>
        <h4
          v-if="costoKmActual != null"
          style="
            margin-right: 0px;
            background-color: #f5f7fa;
            padding: 15px;
            border-radius: 10px;
          "
        >
          COSTO KM ACTUAL: ${{ costoKmActual }}
        </h4>
        <span
          v-else
          style="
            margin-right: 0px;
            background-color: #f5f7fa;
            padding: 20px;
            border-radius: 10px;
          "
        >
          <strong> No está definido el Costo KM para el mes corriente</strong>
        </span>
        <el-button
          class="botonmasIcono mostrarOcultarItem"
          icon="el-icon-money"
          type="primary"
          round
          style="margin-right: 0px; margin-left: 15px"
          @click="$router.push('/costosKm')"
          >Costos Km</el-button
        >
        <el-button
          class="botonmasIcono mostrarOcultarItem"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Nueva Liquidación</el-button
        >

        <el-button
          class="botonmasIcono ocultarMostrarItem"
          icon="el-icon-plus"
          type="primary"
          circle
          style="margin-left: 0.2rem"
          @click="$refs.modalNuevo.abrir()"
        ></el-button>
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column label="Transportista" :min-width="13">
            <template slot-scope="props">
              <span>
                {{
                  props.row.detalleTransportista.centroID == null
                    ? props.row.detalleTransportista.apellido +
                      ", " +
                      props.row.detalleTransportista.nombre
                    : props.row.detalleTransportista.razonSocial
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="mes" label="Mes" :min-width="3">
          </el-table-column>
          <el-table-column prop="anio" label="Año" :min-width="5">
          </el-table-column>
          <el-table-column
            prop="cantSesionesTotal"
            label="Cantidad Sesiones"
            :min-width="5"
          >
          </el-table-column>
          <el-table-column
            prop="cantKmTotal"
            label="Cantidad Km"
            :min-width="5"
          >
          </el-table-column>
          <el-table-column label="Importe Total" :min-width="13">
            <template slot-scope="props">
              <span v-text="'$' + props.row.importeTotal"></span>
            </template>
          </el-table-column>
          <el-table-column label="Imprimir" :min-width="13">
            <template slot-scope="props">
              <el-button
                type="info"
                @click="abrirVentanaPDF(props.row)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-document-copy"></i>
              </el-button>
            </template>
          </el-table-column>

          <!-- <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="warning"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column> -->
          <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
      @click="getCostActual"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>
<style scoped>
.ocultarMostrarItem {
  display: none;
}
.mostrarOcultarItem {
  display: block;
}
@media screen and (max-device-width: 560px) {
  .ocultarMostrarItem {
    display: block;
  }
  .mostrarOcultarItem {
    display: none;
  }
}

.tamañoInputBucar {
  width: 15rem;
}
@media screen and (max-device-width: 385px) {
  .tamañoInputBucar {
    width: 12rem;
  }
}
</style>
<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "usuario",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/liquidacion/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      costoKmActual: null,

      urlTransportista: "/transportista/buscar",
      transportista: null,
      getParams: this.getParamssSelect,

      filtroNombre: null,
    };
  },
  created() {
    this.getCostActual();
  },
  methods: {
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    seleccionSelect() {
      this.paramsTabla = { transportistaID: this.transportista.id };

      this.actualizarTabla = true;
    },
    async abrirVentanaPDF(fila) {
      window
        .open(
          this.$maca.api.APIURL +
            "/liquidacion/generarReporteDetalleViaje?descargar=0&id=" +
            fila.id +
            "&token=" +
            this.$store.state.token,
          "_blank"
        )
        .focus();
    },
    buscar() {
      this.paramsTabla.nombre = this.filtroNombre;
      this.actualizarTabla = true;
    },
    async getCostActual() {
      let respuestaApi = await this.$maca.api.get(
        "/costoKm/obtenerUltimoVigente"
      );

      this.costoKmActual = respuestaApi.datos.importe;
    },

    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar la liquidación."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/liquidacion/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Liquidación borrada con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroNombre: function (n, o) {
      // this.paramsTabla.nombre = this.filtroNombre;
      // this.actualizarTabla = true;
    },
  },
};
</script>
