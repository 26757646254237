<template>
  <div>
    <maca-modal
      titulo="Nueva Liquidación"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          v-loading="loading"
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="170px"
        >
          <div style="text-align: center">
            <el-steps :space="20" :active="active" simple>
              <el-step
                icon="el-icon-calendar"
                title="Rango y transportista"
              ></el-step>
              <el-step icon="el-icon-list" title="Detalles"></el-step>
            </el-steps>
          </div>
          <div v-show="active == 0">
            <br />
            <div class="contenedorStep">
              <strong>
                <label> Seleccionar mes y año a liquidar</label>
                <br />
                <el-row :gutter="10">
                  <el-col :span="10">
                    <el-form-item label="Mes" prop="mes">
                      <el-input-number
                        v-model="form.mes"
                        :min="1"
                        :max="12"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>

                  <el-col :span="10">
                    <el-form-item label="Año" prop="anio">
                      <el-input-number
                        v-model="form.anio"
                        :min="2000"
                        :max="2999"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                </el-row>
              </strong>
            </div>
            <br />

            <div class="contenedorStep">
              <el-row :gutter="10">
                <el-col :span="10">
                  <el-form-item
                    label="Buscar transportista por nombre"
                    prop="nombre"
                  >
                    <maca-select-box
                      placeholder="Ingresar nombre de transportista"
                      :item-label="['apellido', 'nombre','razonSocial']"
                      v-model="form.transportista"
                      :url="urlCentro"
                      :permitirInput="true"
                      :clearable="true"
                      @change="seleccionSelect"
                      :necesitaParams="true"
                      :getParams="getParams"
                    ></maca-select-box>
                  </el-form-item>
                </el-col>

                <el-col :span="7">
                  <!-- <el-form-item label="O filtrar por localidad/paraje">
                    <el-cascader
                      :props="props"
                      :show-all-levels="false"
                      ref="cascader"
                      placeholder="Seleccionar ubicación"
                      @change="handleChange"
                      :clearable="true"
                    ></el-cascader>
                  </el-form-item>

                  <span v-if="ubicacionIncompleta" style="color: red"
                    >Debe seleccionar la localidad o paraje para poder realizar
                    la búsqueda</span
                  > -->
                </el-col>
                <el-col :span="2" style="margin-top: 48px">
                  <!-- <el-button type="success" circle @click="filtrarBusqueda">
                    <i class="el-icon-search"></i>
                  </el-button> -->
                </el-col>
              </el-row>

              <maca-datatable
                :url="urlTabla"
                :params="paramsTabla"
                :actualizar.sync="actualizarTabla"
                :bloquear.sync="bloquearTabla"
                v-if="mostrarTabla"
              >
                <el-table-column prop="cuit" label="CUIT" :min-width="10">
                </el-table-column>

                <el-table-column
                  prop="apellido"
                  label="Apellido"
                  :min-width="10"
                >
                </el-table-column>

                <el-table-column prop="nombre" label="Nombre" :min-width="10">
                </el-table-column>

                <el-table-column
                  prop="razonSocial"
                  label="Razon Social"
                  :min-width="10"
                >
                </el-table-column>

                <el-table-column label="Direccion" :min-width="20">
                  <template slot-scope="props">
                    <span
                      v-if="props.row.detalleParaje != null"
                      v-text="props.row.detalleParaje.nombre + ', '"
                    >
                    </span>
                    <span
                      v-if="props.row.detalleLocalidad != null"
                      v-text="props.row.detalleLocalidad.nombre + ', '"
                    >
                    </span>
                    <span
                      v-if="props.row.detalleDepartamento != null"
                      v-text="props.row.detalleDepartamento.nombre"
                    >
                    </span>
                    <span
                      v-if="props.row.detalleProvincia != null"
                      v-text="+' - ' + props.row.detalleProvincia.nombre"
                    >
                    </span>
                  </template>
                </el-table-column>

                <el-table-column width="70">
                  <template slot-scope="props">
                    <el-button
                      type="success"
                      circle
                      @click="calcularLiquidacion(props.row)"
                    >
                      <i class="el-icon-right"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </maca-datatable>
            </div>
          </div>

          <div v-show="active == 1">
            <br /><br />
            <div class="contenedorStep">
              <el-table-column style="margin-right: 5px; float: right">
                <span
                  >Liquidación del <strong> {{ form.mes }}</strong> del
                  <strong>{{ form.anio }}</strong></span
                >
              </el-table-column>
              <el-table-column>
                <span
                  ><strong>{{ this.form.nombreTransp }}</strong></span
                >
              </el-table-column>
              <br />
              <el-table-column style="float: right; font-size: 16px">
                <br />

                <span class="contenedorStepBlanco"
                  ><strong
                    >Importe por Km: ${{ form.importePorKm }}</strong
                  ></span
                >
              </el-table-column>
              <br />
              <br />
              <br />
            </div>
            <br />
            <div class="contenedorStep" style="padding: 15px">
              <el-row>
                <el-button
                  style="float: right"
                  circle
                  @click="mostrarDetalleViajeFuncion"
                  :disabled="!$store.getters.tienePermiso('B_USE')"
                >
                  <i v-show="!mostrarDetalleViaje" class="el-icon-plus"></i>
                  <i v-show="mostrarDetalleViaje" class="el-icon-minus"></i>
                </el-button>
                <strong> Detalle de viajes</strong>
              </el-row>

              <br />
              <span
                class="contenedorStepBlanco"
                style="float: right; font-size: 16px"
                ><strong
                  >Importe total de viajes: ${{ importeTotalViaje() }}</strong
                >
              </span>
              <br />
              <br /><br />

              <el-table-column
                v-show="mostrarDetalleViaje"
                v-if="form.detalleViajes != null"
                type="expand"
                label="Detalle de viajes"
              >
                <div v-for="(child, j) in form.detalleViajes" :key="j">
                  <br />
                  <div class="contenedorStepBlanco">
                    <strong>
                      <strong>**</strong>
                      <p>
                        <span v-text="'Viaje ID: ' + child.id"> </span>
                      </p>
                      <p v-if="child.reasignado == 1">
                        <span
                          v-text="
                            `REASIGNADO ${$maca.common.formatearFecha(
                              child.reasignadoFecha
                            )} - ${child.reasignadoObservaciones}`
                          "
                        >
                        </span>
                      </p>
                      <el-row :gutter="14">
                        <el-col :span="12">
                          <p>
                            <span
                              v-text="
                                'Creado: ' +
                                $maca.common.formatearFecha(child.fechaCreado)
                              "
                            >
                            </span>
                          </p>
                        </el-col>
                        <el-col :span="12">
                          <p>
                            <span></span>
                              {{
                                `Fecha desde ${$maca.common.formatearFecha(
                                  child.fechaDesde
                                )} hasta ${
                                  child.fechaHasta != null
                                    ? $maca.common.formatearFecha(
                                        child.fechaHasta
                                      )
                                    : "indeterminado"
                                }`
                              }}
                            </span>
                          </p>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <p>
                            <strong>
                              <span
                                v-text="
                                  'Cantidad de sesiones reales: ' +
                                  child.cantSesiones
                                "
                              >
                              </span>
                            </strong>
                          </p>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item
                            label="Cantidad total de sesiones pagadas"
                          >
                            <el-input-number
                              :min="0"
                              @change="
                                calcularImporte(
                                  child.totalKmAutorizado,
                                  form.sesionesPagadas[j],
                                  child
                                )
                              "
                              v-model="form.sesionesPagadas[j]"
                            >
                            </el-input-number>
                            <el-input
                              type="textarea"
                              :autosize="{ minRows: 2, maxRows: 4 }"
                              placeholder="Observaciones"
                              v-model="child.obvservacionSesionesPagadas"
                            >
                            </el-input>
                          </el-form-item>
                          <!-- 
                   <p> <strong>Cantidad total de viajes: {{ this.form.cantViajesTotal }}</strong></p> -->
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <p>
                            <span
                              v-text="
                                'Total de Km autorizado: ' +
                                child.totalKmAutorizado
                              "
                            >
                            </span></p
                        ></el-col>
                        <el-col :span="12">
                          <p>
                            <span
                              v-text="
                                'Importe viaje: $' +
                                calcularImporte(
                                  child.totalKmAutorizado,
                                  form.sesionesPagadas[j],
                                  child
                                )
                              "
                            >
                            </span>
                          </p>
                        </el-col>
                      </el-row>
                    </strong>
                  </div>
                </div>
              </el-table-column>
            </div>

            <br />
            <div class="contenedorStep" style="padding: 15px">
              <strong>
                <span>Ajuste</span>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item
                      label="Importe del ajuste"
                      prop="importeAjuste"
                      :placeholder="form.importeViajes"
                    >
                      <el-input v-model="form.importeAjuste">
                        <template slot="prepend">$</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Observaciones del ajuste">
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="Observaciones"
                        v-model="form.observacionesAjuste"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </strong>
            </div>
            <br />

            <div class="contenedorStep" style="padding: 15px">
              <el-form-item label="Observaciones Generales">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="Observaciones"
                  v-model="form.observacionesGeneral"
                >
                </el-input>
              </el-form-item>
              <span
                class="contenedorStepBlanco"
                style="float: right; font-size: 16px"
                ><strong>Importe total: ${{ importeTotalFinal() }}</strong>
              </span>
              <br /><br /><br />
            </div>
          </div>
          <br />

          <div style="display: flex">
            <div style="margin: auto; margin-right: 0px">
              <el-button
                style="margin-top: 15px"
                v-show="active != 0"
                @click="atras"
                >Atras</el-button
              >
              <el-button
                v-show="active != 0"
                style="margin-top: 15px"
                @click="liquidar"
                >Liquidar</el-button
              >
            </div>
          </div>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
.contenedorStep {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
.contenedorStepBlanco {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
</style>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    let _self = this;
    return {
      // props: {
      //   lazy: true,
      //   lazyLoad(node, resolve) {
      //     const { level } = node;

      //     if (level === 0) {
      //       _self.$maca.api.get("/pais/obtenerTodos", null).then((res) => {
      //         const nodes = res.datos.map((item) => ({
      //           value: item.id,
      //           label: item.nombre,
      //         }));

      //         resolve(nodes);
      //       });
      //     } else if (level === 1) {
      //       let params = { paisID: node.data.value, sinPaginar: 1 };
      //       _self.$maca.api
      //         .get("/provincia/obtenerTodos", params)
      //         .then((res) => {
      //           const nodes = res.datos.map((item) => ({
      //             value: item.id,
      //             label: item.nombre,
      //           }));

      //           resolve(nodes);
      //         });
      //     } else if (level === 2) {
      //       // form.provincia = node.data.value;
      //       let params = { provinciaID: node.data.value, sinPaginar: 1 };
      //       _self.$maca.api
      //         .get("/departamento/obtenerTodos", params)
      //         .then((res) => {
      //           const nodes = res.datos.map((item) => ({
      //             value: item.id,
      //             label: item.nombre,
      //           }));
      //           resolve(nodes);
      //         });
      //     } else if (level === 3) {
      //       let params = { departamentoID: node.data.value, sinPaginar: 1 };
      //       _self.$maca.api
      //         .get("/localidad/obtenerTodos", params)
      //         .then((res) => {
      //           const nodes = res.datos.map((item) => ({
      //             value: item.id,
      //             label: item.nombre,
      //           }));

      //           resolve(nodes);
      //         });
      //     } else if (level === 4) {
      //       let params = { localidadID: node.data.value, sinPaginar: 1 };
      //       _self.$maca.api.get("/paraje/obtenerTodos", params).then((res) => {
      //         const nodes = res.datos.map((item) => ({
      //           value: item.id,
      //           label: item.nombre,
      //         }));

      //         resolve(nodes);
      //       });
      //     } else {
      //       // form.paraje = node.data.value;
      //       resolve(null);
      //     }
      //   },
      // },
      form: {
        mes: null,
        anio: null,
        transportista: null,
        nombreTransp: null,

        importePorKm: null,
        importeViajes: null,
        cantSesionesTotal: null,
        cantKmTotal: null,
        cantViajesTotal: null,
        detalleViajes: [],
        sesionesPagadas: [],

        observacionesGeneral: null,

        cantSesionesTotalReal: null,
        importePorKmReal: null,
        cantKmTotalReal: null,
        cantViajesTotalReal: null,
        importeViajesReal: null,
        observacionesViaje: null,
        importeAjuste: 0,
        observacionesAjuste: null,
      },
      totalViajes: 0,
      transportistaID: null,
      impedirClose: false,
      persona: null,
      idPadre: 0,
      urlProvincia: "/provincia/obtenerTodosSelect",

      urlTabla: "/transportista/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      mostrarTabla: false,

      costoKmActual: null,

      formRules: {
        importePorKmReal: [
          {
            required: true,
            message: "Por favor introduzca el importe por km real.",
            trigger: "change",
          },
        ],
        cantKmTotalReal: [
          {
            required: true,
            message: "Por favor introduzca la cantidad de km real.",
            trigger: "change",
          },
        ],
        cantViajesTotalReal: [
          {
            required: true,
            message: "Por favor introduzca la cantidad viajes real.",
            trigger: "change",
          },
        ],
        importeViajesReal: [
          {
            required: true,
            message: "Por favor introduzca el importe de viaje real.",
            trigger: "change",
          },
        ],
        cantSesionesTotalReal: [
          {
            required: true,
            message: "Por favor introduzca la cantidad de sesiones real.",
            trigger: "change",
          },
        ],
      },
      urlCentro: "/transportista/buscar",
      getParams: this.getParamssSelect,
      ubicacionActual: true,
      ubicacionIncompleta: false,
      esLocalidad: false,
      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      active: 0,

      mostrarDetalleViaje: true,

      loading: false,
    };
  },
  mounted() {
    this.filtrarBusqueda();
  },
  methods: {
    importeTotalFinal() {
      return (
        this.importeTotalViaje() +
        parseFloat(this.form.importeAjuste == "" ? 0 : this.form.importeAjuste)
      );
    },
    importeTotalViaje() {
      let total = 0;
      for (let i = 0; i < this.form.detalleViajes.length; i++) {
        total =
          total + parseFloat(this.form.detalleViajes[i].importeTotalViaje);
      }
      return total;
    },
    calcularImporte(kmAutorizado, sesionesPagadas, item) {
      item.importeTotalViaje =
        kmAutorizado * sesionesPagadas * parseFloat(this.form.importePorKm);
      return parseFloat(item.importeTotalViaje);
    },
    atras() {
      this.active = 0;
    },
    mostrarDetalleViajeFuncion() {
      if (this.mostrarDetalleViaje) this.mostrarDetalleViaje = false;
      else this.mostrarDetalleViaje = true;
    },
    async calcularLiquidacion(datos) {
      this.form.nombreTransp =
        datos.detalleCentro == null
          ? datos.apellido + ", " + datos.nombre
          : datos.razonSocial;

      this.transportistaID = datos.id;
      let params = {
        transportistaID: datos.id,
        anio: this.form.anio,
        mes: this.form.mes,
      };
      let respuestaApi = await this.$maca.api.post(
        "/liquidacion/calcular",
        params
      );

      if (respuestaApi != null) {
        if (
          respuestaApi.estado == 1 &&
          respuestaApi.datos.detalleViajes.length != 0
        ) {
          console.log(respuestaApi.datos.detalleViajes);
          this.form.importePorKm = respuestaApi.datos.importePorKm;
          this.form.importeViajes = respuestaApi.datos.importeViajes;
          this.form.cantSesionesTotal = respuestaApi.datos.cantSesionesTotal;
          this.form.cantKmTotal = respuestaApi.datos.cantKmTotal;
          this.form.cantViajesTotal = respuestaApi.datos.cantViajesTotal;
          this.form.detalleViajes = respuestaApi.datos.detalleViajes;
          for (let i = 0; i < this.form.detalleViajes.length; i++) {
            this.form.sesionesPagadas.push(
              this.form.detalleViajes[i].cantSesiones
            );
          }
          this.active++;
        } else if (
          respuestaApi.estado == 1 &&
          respuestaApi.datos.detalleViajes.length == 0
        ) {
          this.$message({
            message:
              "No se registran viajes para el Año y Mes del transportista",
            type: "error",
            showClose: true,
            duration: 0,
          });
        } else {
          this.$message({
            message: respuestaApi.excepcion,
            type: "error",
            showClose: true,
            duration: 0,
          });
        }
      }
    },
    filtrarBusqueda() {
      if (this.esLocalidad) {
        this.paramsTabla = { localidadID: this.localidadID };
      } else {
        this.paramsTabla = { parajeID: this.parajeID };
      }
      this.mostrarTabla = true;
      this.actualizarTabla = true;
    },
    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        if (node.length == 4) {
          this.esLocalidad = true;
        } else this.esLocalidad = false;

        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    seleccionSelect() {
      this.paramsTabla = { transportistaID: this.form.transportista.id };

      this.mostrarTabla = true;
      this.actualizarTabla = true;
    },
    async getCostActual() {
      let respuestaApi = await this.$maca.api.get(
        "/costoKm/obtenerUltimoVigente"
      );

      this.costoKmActual = respuestaApi.datos.importe;
    },

    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar la liquidación."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/liquidacion/eliminar",
        params
      );

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Liquidación borrada con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.persona = null;
      let fecha = new Date();
      this.form.mes = fecha.getMonth() + 1;
      this.form.anio = fecha.getFullYear();
      this.resetearValores();
    },
    resetearValores() {
      this.active = 0;

      this.mostrarDetalleViaje = true;
      this.costoKmActual = null;

      this.form.transportista = null;
      this.paramsTabla = {};
      this.form.nombreTransp = null;

      this.form.importePorKm = null;
      this.form.importeViajes = null;
      this.form.cantSesionesTotal = null;
      this.form.cantKmTotal = null;
      this.form.cantViajesTotal = null;
      this.form.detalleViajes = [];
      this.form.sesionesPagadas = [];
      this.form.observacionesGeneral = null;

      this.form.cantSesionesTotalReal = null;
      this.form.importePorKmReal = null;
      this.form.cantKmTotalReal = null;
      this.form.cantViajesTotalReal = null;
      this.form.importeViajesReal = null;
      this.form.observacionesViaje = null;
      this.form.importeAjuste = 0;
      this.form.observacionesAjuste = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async liquidar() {
      let importeViajes = 0;
      let importeTotal = 0;
      let detalleViaje = [];
      for (let i = 0; i < this.form.detalleViajes.length; i++) {
        importeViajes =
          importeViajes +
          parseFloat(this.form.detalleViajes[i].importeTotalViaje);
        importeTotal = importeTotal + parseFloat(importeViajes);
        detalleViaje.push({
          importe: this.form.detalleViajes[i].importeTotalViaje,
          viajeID: this.form.detalleViajes[i].id,
          cantSesionesReales: this.form.detalleViajes[i].cantSesiones,
          cantSesionesPagadas: this.form.sesionesPagadas[i],
          cantKmAutorizados: this.form.detalleViajes[i].totalKmAutorizado,
          observaciones:
            this.form.detalleViajes[i].obvservacionSesionesPagadas != null
              ? this.form.detalleViajes[i].obvservacionSesionesPagadas
              : "",
        });
      }
      console.log();
      importeTotal = importeTotal + parseFloat(this.form.importeAjuste);
      let detalleAjuste = [
        {
          importe: this.form.importeAjuste != "" ? this.form.importeAjuste : 0,
          observaciones: this.form.observacionesAjuste,
        },
      ];

      // Hacer post
      let params = {
        transportistaID: this.transportistaID,
        anio: this.form.anio,
        mes: this.form.mes,
        importeTotal: importeTotal,
        importeViajes: importeViajes,
        importeAjustes:
          this.form.importeAjuste != "" ? this.form.importeAjuste : 0,
        observaciones:
          this.form.observacionesGeneral != null
            ? this.form.observacionesGeneral
            : "",
        detalleViaje: JSON.stringify(detalleViaje),
        detalleAjuste: JSON.stringify(detalleAjuste),
      };

      this.loading = true;
      let respuestaApi = await this.$maca.api.post(
        "/liquidacion/crear",
        params
      );

      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Liquidación creada con éxito!",
          type: "success",
        });

        this.$emit("listo");
        this.$emit(this.$maca.eventStrings._actualizarTabla);
        this.cerrar();
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      this.loading = false;
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        clave: this.form.clave,
        valor: this.form.valor,
        tipoDato: this.form.tipoDato,
        descripcion: this.form.descripcion,
      };

      let respuestaApi = await this.$maca.api.post(
        "/configuracion/crear",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Departamento creado con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>