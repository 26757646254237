var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Nueva Liquidación", impedirClose: _vm.impedirClose }
        },
        [
          _c(
            "el-card",
            {
              staticClass: "mostrarOcultarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "top",
                    "label-width": "170px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-steps",
                        {
                          attrs: { space: 20, active: _vm.active, simple: "" }
                        },
                        [
                          _c("el-step", {
                            attrs: {
                              icon: "el-icon-calendar",
                              title: "Rango y transportista"
                            }
                          }),
                          _c("el-step", {
                            attrs: { icon: "el-icon-list", title: "Detalles" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active == 0,
                          expression: "active == 0"
                        }
                      ]
                    },
                    [
                      _c("br"),
                      _c("div", { staticClass: "contenedorStep" }, [
                        _c(
                          "strong",
                          [
                            _c("label", [
                              _vm._v(" Seleccionar mes y año a liquidar")
                            ]),
                            _c("br"),
                            _c(
                              "el-row",
                              { attrs: { gutter: 10 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 10 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Mes", prop: "mes" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: { min: 1, max: 12 },
                                          model: {
                                            value: _vm.form.mes,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "mes", $$v)
                                            },
                                            expression: "form.mes"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 10 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Año", prop: "anio" } },
                                      [
                                        _c("el-input-number", {
                                          attrs: { min: 2000, max: 2999 },
                                          model: {
                                            value: _vm.form.anio,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "anio", $$v)
                                            },
                                            expression: "form.anio"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("br"),
                      _c(
                        "div",
                        { staticClass: "contenedorStep" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          "Buscar transportista por nombre",
                                        prop: "nombre"
                                      }
                                    },
                                    [
                                      _c("maca-select-box", {
                                        attrs: {
                                          placeholder:
                                            "Ingresar nombre de transportista",
                                          "item-label": [
                                            "apellido",
                                            "nombre",
                                            "razonSocial"
                                          ],
                                          url: _vm.urlCentro,
                                          permitirInput: true,
                                          clearable: true,
                                          necesitaParams: true,
                                          getParams: _vm.getParams
                                        },
                                        on: { change: _vm.seleccionSelect },
                                        model: {
                                          value: _vm.form.transportista,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "transportista",
                                              $$v
                                            )
                                          },
                                          expression: "form.transportista"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 7 } }),
                              _c("el-col", {
                                staticStyle: { "margin-top": "48px" },
                                attrs: { span: 2 }
                              })
                            ],
                            1
                          ),
                          _vm.mostrarTabla
                            ? _c(
                                "maca-datatable",
                                {
                                  attrs: {
                                    url: _vm.urlTabla,
                                    params: _vm.paramsTabla,
                                    actualizar: _vm.actualizarTabla,
                                    bloquear: _vm.bloquearTabla
                                  },
                                  on: {
                                    "update:actualizar": function($event) {
                                      _vm.actualizarTabla = $event
                                    },
                                    "update:bloquear": function($event) {
                                      _vm.bloquearTabla = $event
                                    }
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "cuit",
                                      label: "CUIT",
                                      "min-width": 10
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "apellido",
                                      label: "Apellido",
                                      "min-width": 10
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "nombre",
                                      label: "Nombre",
                                      "min-width": 10
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "razonSocial",
                                      label: "Razon Social",
                                      "min-width": 10
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Direccion",
                                      "min-width": 20
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(props) {
                                            return [
                                              props.row.detalleParaje != null
                                                ? _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        props.row.detalleParaje
                                                          .nombre + ", "
                                                      )
                                                    }
                                                  })
                                                : _vm._e(),
                                              props.row.detalleLocalidad != null
                                                ? _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        props.row
                                                          .detalleLocalidad
                                                          .nombre + ", "
                                                      )
                                                    }
                                                  })
                                                : _vm._e(),
                                              props.row.detalleDepartamento !=
                                              null
                                                ? _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        props.row
                                                          .detalleDepartamento
                                                          .nombre
                                                      )
                                                    }
                                                  })
                                                : _vm._e(),
                                              props.row.detalleProvincia != null
                                                ? _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        +" - " +
                                                          props.row
                                                            .detalleProvincia
                                                            .nombre
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2632238365
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { width: "70" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(props) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "success",
                                                    circle: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.calcularLiquidacion(
                                                        props.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-right"
                                                  })
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      539081202
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active == 1,
                          expression: "active == 1"
                        }
                      ]
                    },
                    [
                      _c("br"),
                      _c("br"),
                      _c(
                        "div",
                        { staticClass: "contenedorStep" },
                        [
                          _c(
                            "el-table-column",
                            {
                              staticStyle: {
                                "margin-right": "5px",
                                float: "right"
                              }
                            },
                            [
                              _c("span", [
                                _vm._v("Liquidación del "),
                                _c("strong", [
                                  _vm._v(" " + _vm._s(_vm.form.mes))
                                ]),
                                _vm._v(" del "),
                                _c("strong", [_vm._v(_vm._s(_vm.form.anio))])
                              ])
                            ]
                          ),
                          _c("el-table-column", [
                            _c("span", [
                              _c("strong", [
                                _vm._v(_vm._s(this.form.nombreTransp))
                              ])
                            ])
                          ]),
                          _c("br"),
                          _c(
                            "el-table-column",
                            {
                              staticStyle: {
                                float: "right",
                                "font-size": "16px"
                              }
                            },
                            [
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "contenedorStepBlanco" },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      "Importe por Km: $" +
                                        _vm._s(_vm.form.importePorKm)
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c("br"),
                          _c("br"),
                          _c("br")
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticClass: "contenedorStep",
                          staticStyle: { padding: "15px" }
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { float: "right" },
                                  attrs: {
                                    circle: "",
                                    disabled: !_vm.$store.getters.tienePermiso(
                                      "B_USE"
                                    )
                                  },
                                  on: { click: _vm.mostrarDetalleViajeFuncion }
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.mostrarDetalleViaje,
                                        expression: "!mostrarDetalleViaje"
                                      }
                                    ],
                                    staticClass: "el-icon-plus"
                                  }),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.mostrarDetalleViaje,
                                        expression: "mostrarDetalleViaje"
                                      }
                                    ],
                                    staticClass: "el-icon-minus"
                                  })
                                ]
                              ),
                              _c("strong", [_vm._v(" Detalle de viajes")])
                            ],
                            1
                          ),
                          _c("br"),
                          _c(
                            "span",
                            {
                              staticClass: "contenedorStepBlanco",
                              staticStyle: {
                                float: "right",
                                "font-size": "16px"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "Importe total de viajes: $" +
                                    _vm._s(_vm.importeTotalViaje())
                                )
                              ])
                            ]
                          ),
                          _c("br"),
                          _c("br"),
                          _c("br"),
                          _vm.form.detalleViajes != null
                            ? _c(
                                "el-table-column",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.mostrarDetalleViaje,
                                      expression: "mostrarDetalleViaje"
                                    }
                                  ],
                                  attrs: {
                                    type: "expand",
                                    label: "Detalle de viajes"
                                  }
                                },
                                _vm._l(_vm.form.detalleViajes, function(
                                  child,
                                  j
                                ) {
                                  return _c("div", { key: j }, [
                                    _c("br"),
                                    _c(
                                      "div",
                                      { staticClass: "contenedorStepBlanco" },
                                      [
                                        _c(
                                          "strong",
                                          [
                                            _c("strong", [_vm._v("**")]),
                                            _c("p", [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    "Viaje ID: " + child.id
                                                  )
                                                }
                                              })
                                            ]),
                                            child.reasignado == 1
                                              ? _c("p", [
                                                  _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        "REASIGNADO " +
                                                          _vm.$maca.common.formatearFecha(
                                                            child.reasignadoFecha
                                                          ) +
                                                          " - " +
                                                          child.reasignadoObservaciones
                                                      )
                                                    }
                                                  })
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 14 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c("p", [
                                                      _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            "Creado: " +
                                                              _vm.$maca.common.formatearFecha(
                                                                child.fechaCreado
                                                              )
                                                          )
                                                        }
                                                      })
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c("p", [
                                                      _c("span"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "Fecha desde " +
                                                              _vm.$maca.common.formatearFecha(
                                                                child.fechaDesde
                                                              ) +
                                                              " hasta " +
                                                              (child.fechaHasta !=
                                                              null
                                                                ? _vm.$maca.common.formatearFecha(
                                                                    child.fechaHasta
                                                                  )
                                                                : "indeterminado")
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 24 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c("p", [
                                                      _c("strong", [
                                                        _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              "Cantidad de sesiones reales: " +
                                                                child.cantSesiones
                                                            )
                                                          }
                                                        })
                                                      ])
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "Cantidad total de sesiones pagadas"
                                                        }
                                                      },
                                                      [
                                                        _c("el-input-number", {
                                                          attrs: { min: 0 },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.calcularImporte(
                                                                child.totalKmAutorizado,
                                                                _vm.form
                                                                  .sesionesPagadas[
                                                                  j
                                                                ],
                                                                child
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .sesionesPagadas[
                                                                j
                                                              ],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .sesionesPagadas,
                                                                j,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.sesionesPagadas[j]"
                                                          }
                                                        }),
                                                        _c("el-input", {
                                                          attrs: {
                                                            type: "textarea",
                                                            autosize: {
                                                              minRows: 2,
                                                              maxRows: 4
                                                            },
                                                            placeholder:
                                                              "Observaciones"
                                                          },
                                                          model: {
                                                            value:
                                                              child.obvservacionSesionesPagadas,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                child,
                                                                "obvservacionSesionesPagadas",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "child.obvservacionSesionesPagadas"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 20 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c("p", [
                                                      _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            "Total de Km autorizado: " +
                                                              child.totalKmAutorizado
                                                          )
                                                        }
                                                      })
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c("p", [
                                                      _c("span", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            "Importe viaje: $" +
                                                              _vm.calcularImporte(
                                                                child.totalKmAutorizado,
                                                                _vm.form
                                                                  .sesionesPagadas[
                                                                  j
                                                                ],
                                                                child
                                                              )
                                                          )
                                                        }
                                                      })
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticClass: "contenedorStep",
                          staticStyle: { padding: "15px" }
                        },
                        [
                          _c(
                            "strong",
                            [
                              _c("span", [_vm._v("Ajuste")]),
                              _c(
                                "el-row",
                                { attrs: { gutter: 24 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Importe del ajuste",
                                            prop: "importeAjuste",
                                            placeholder: _vm.form.importeViajes
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: _vm.form.importeAjuste,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "importeAjuste",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.importeAjuste"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "prepend" },
                                                [_vm._v("$")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Observaciones del ajuste"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: {
                                                minRows: 2,
                                                maxRows: 4
                                              },
                                              placeholder: "Observaciones"
                                            },
                                            model: {
                                              value:
                                                _vm.form.observacionesAjuste,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "observacionesAjuste",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.observacionesAjuste"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticClass: "contenedorStep",
                          staticStyle: { padding: "15px" }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Observaciones Generales" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2, maxRows: 4 },
                                  placeholder: "Observaciones"
                                },
                                model: {
                                  value: _vm.form.observacionesGeneral,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "observacionesGeneral",
                                      $$v
                                    )
                                  },
                                  expression: "form.observacionesGeneral"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "contenedorStepBlanco",
                              staticStyle: {
                                float: "right",
                                "font-size": "16px"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "Importe total: $" +
                                    _vm._s(_vm.importeTotalFinal())
                                )
                              ])
                            ]
                          ),
                          _c("br"),
                          _c("br"),
                          _c("br")
                        ],
                        1
                      )
                    ]
                  ),
                  _c("br"),
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { margin: "auto", "margin-right": "0px" }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.active != 0,
                                expression: "active != 0"
                              }
                            ],
                            staticStyle: { "margin-top": "15px" },
                            on: { click: _vm.atras }
                          },
                          [_vm._v("Atras")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.active != 0,
                                expression: "active != 0"
                              }
                            ],
                            staticStyle: { "margin-top": "15px" },
                            on: { click: _vm.liquidar }
                          },
                          [_vm._v("Liquidar")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }