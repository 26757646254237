var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("maca-titulo-pagina", {
        attrs: { icono: "icon-user", nombrePagina: "Liquidaciones" }
      }),
      _c(
        "el-card",
        {
          staticStyle: { margin: "2%" },
          attrs: { "body-style": { padding: "1.5%" } }
        },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "div",
                { staticStyle: { "margin-left": "0px" } },
                [
                  _c("maca-select-box", {
                    staticClass: "buscador tamañoInputBucar",
                    attrs: {
                      placeholder: "Ingresar nombre de transportista",
                      "item-label": ["apellido", "nombre", "razonSocial"],
                      url: _vm.urlTransportista,
                      permitirInput: true,
                      clearable: true,
                      necesitaParams: true,
                      getParams: _vm.getParams
                    },
                    on: { change: _vm.seleccionSelect },
                    model: {
                      value: _vm.transportista,
                      callback: function($$v) {
                        _vm.transportista = $$v
                      },
                      expression: "transportista"
                    }
                  })
                ],
                1
              ),
              _vm.costoKmActual != null
                ? _c(
                    "h4",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "background-color": "#f5f7fa",
                        padding: "15px",
                        "border-radius": "10px"
                      }
                    },
                    [
                      _vm._v(
                        " COSTO KM ACTUAL: $" + _vm._s(_vm.costoKmActual) + " "
                      )
                    ]
                  )
                : _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-right": "0px",
                        "background-color": "#f5f7fa",
                        padding: "20px",
                        "border-radius": "10px"
                      }
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          " No está definido el Costo KM para el mes corriente"
                        )
                      ])
                    ]
                  ),
              _c(
                "el-button",
                {
                  staticClass: "botonmasIcono mostrarOcultarItem",
                  staticStyle: { "margin-right": "0px", "margin-left": "15px" },
                  attrs: { icon: "el-icon-money", type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/costosKm")
                    }
                  }
                },
                [_vm._v("Costos Km")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "botonmasIcono mostrarOcultarItem",
                  staticStyle: { "margin-right": "0px" },
                  attrs: { icon: "el-icon-plus", type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.modalNuevo.abrir()
                    }
                  }
                },
                [_vm._v("Nueva Liquidación")]
              ),
              _c("el-button", {
                staticClass: "botonmasIcono ocultarMostrarItem",
                staticStyle: { "margin-left": "0.2rem" },
                attrs: { icon: "el-icon-plus", type: "primary", circle: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "maca-datatable",
                {
                  attrs: {
                    url: _vm.urlTabla,
                    params: _vm.paramsTabla,
                    actualizar: _vm.actualizarTabla,
                    bloquear: _vm.bloquearTabla
                  },
                  on: {
                    "update:actualizar": function($event) {
                      _vm.actualizarTabla = $event
                    },
                    "update:bloquear": function($event) {
                      _vm.bloquearTabla = $event
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "Transportista", "min-width": 13 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.row.detalleTransportista.centroID ==
                                      null
                                      ? props.row.detalleTransportista
                                          .apellido +
                                          ", " +
                                          props.row.detalleTransportista.nombre
                                      : props.row.detalleTransportista
                                          .razonSocial
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "mes", label: "Mes", "min-width": 3 }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "anio", label: "Año", "min-width": 5 }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cantSesionesTotal",
                      label: "Cantidad Sesiones",
                      "min-width": 5
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cantKmTotal",
                      label: "Cantidad Km",
                      "min-width": 5
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Importe Total", "min-width": 13 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  "$" + props.row.importeTotal
                                )
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Imprimir", "min-width": 13 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "info",
                                  circle: "",
                                  disabled: !_vm.$store.getters.tienePermiso(
                                    "B_USE"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.abrirVentanaPDF(props.row)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-copy"
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  circle: "",
                                  disabled: !_vm.$store.getters.tienePermiso(
                                    "B_USE"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.eliminar(props.row.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          },
          click: _vm.getCostActual
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }