<template>
  <div>
    <maca-modal
      titulo="Modificar Configuración"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        v-loading="cargando"
        label-position="left"
        label-width="130px"
      >
        <el-form-item label="Clave" prop="clave">
          <el-input maxlength="30" v-model="form.clave"></el-input>
        </el-form-item>
        <el-form-item label="Valor" prop="valor">
          <el-input maxlength="30" v-model="form.valor"></el-input>
        </el-form-item>

        <el-form-item label="Tipo de dato" prop="tipoDato">
          <el-input maxlength="30" v-model="form.tipoDato"></el-input>
        </el-form-item>

        <el-form-item label="Descripcion" prop="descripcion">
          <el-input maxlength="30" v-model="form.descripcion"></el-input>
        </el-form-item>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-usuario",
  data() {
    return {
      urlProvincia: "/provincia/obtenerTodosSelect",
      form: {
        clave: null,
        valor: null,
        descripcion: null,
        tipoDato: null,
      },
      impedirClose: false,
      cargando: false,
      id: 0,
      idPadre: 0,
      persona: null,

      formRules: {
        clave: [
          {
            required: true,
            message: "Por favor introduzca la clave.",
            trigger: "change",
          },
        ],
        valor: [
          {
            required: true,
            message: "Por favor introduzca el valor.",
            trigger: "change",
          },
        ],
        descripcion: [
          {
            required: true,
            message: "Por favor introduzca la descripción.",
            trigger: "change",
          },
        ],
        tipoDato: [
          {
            required: true,
            message: "Por favor introduzca el tipo de dato.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id, idPadre) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.idPadre = idPadre;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get(
        "/configuracion/obtenerDatos",
        {
          id: this.id,
        }
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.valor = respuestaApi.datos.valor;
        this.form.clave = respuestaApi.datos.clave;
        this.form.tipoDato = respuestaApi.datos.tipoDato;
        this.form.descripcion = respuestaApi.datos.descripcion;
      } else {
        //this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        id: this.id,
        valor: this.form.valor,
        tipoDato: this.form.tipoDato,
        descripcion: this.form.descripcion,
      };

      let respuestaApi = await this.$maca.api.post(
        "/configuracion/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Configuración actualizada con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
